<template>
  <div class="number-box flex">
    <a-button class="button flex" :disabled="value <= 1" type="primary" ghost @click="onMinusHandler">
      <a-icon type="minus" />
    </a-button>
    <a-input-number :value="value" :disabled="max === 1" :min="1" :max="max" @change="handlechange"/>
    <a-button class="button flex" :disabled="value >= max" type="primary" ghost @click="onPlusHandler">
      <a-icon type="plus" />
    </a-button>
  </div>
</template>
<script>
import { InputNumber } from 'ant-design-vue'
export default {
  name:'NumberBox',
  components: {
    [InputNumber.name]: InputNumber,
  },
  props: {
    value: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {

    }
  },
  methods: {
    handlechange(value){
      if(value > this.max || value % 1 !== 0) return
      if(!value) return this.$emit('change',this.value)
      this.$emit('change',value)
    },
    onMinusHandler() {
      if (this.value <= 1) return
      this.$emit('change', this.value - 1);
    },
    onPlusHandler() {
      if(this.value > this.max) return
      this.$emit('change', this.value + 1);
    }
  },
}
</script>
<style lang="scss" scoped>
.number-box {
  /deep/ .button {
    padding: 0;
    width: 30px;
    height: 30px;
    border-width: 2px;
    border-radius: 0;
  }

  /deep/ .ant-input-number {
    box-sizing: border-box;
    box-shadow: none;
    border-color: transparent;

    .ant-input-number-handler-wrap {
      display: none;
    }

    .ant-input-number-input-wrap {
      .ant-input-number-input {
        color: #222222;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
      }
    }
  }
}
</style>