<template>
  <div class="details">
    <a-spin :spinning="isLoading" size="large">
      <div class="container">
        <a-breadcrumb separator="" class="page-breadcrumb" v-if="iccid">
          <a-breadcrumb-item>
            <router-link to="/mycards">
              {{ $t('route.my_cards') }}
            </router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-separator>
            <a-icon type="right" />
          </a-breadcrumb-separator>
          <a-breadcrumb-item>
            <router-link :to="`/topup?iccid=${iccid}`" v-if="iccid">
              {{ $t('route.topup') }}
            </router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-separator>
            <a-icon type="right" />
          </a-breadcrumb-separator>
          <a-breadcrumb-item>{{ $t('mycards.topup_title') }}</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="section-title" v-if="iccid">
          <div>{{ $t('mycards.topup_title') }}</div>
          <div class="section-subtitle">{{ $t('mycards.topup_subtitle') }}</div>
        </div>
        <topup-search v-if="iccid" :iccid="iccid" />
        <a-row :gutter="[20, 10]">
          <a-col :xs="24" :sm="24" :md="24" :lg="10" :xl="8">
            <img class="product-img" :src="product.cover" alt="">
          </a-col>
          <a-col :xs="24" :sm="24" :md="24" :lg="14" :xl="16">
            <div class="product-name">{{ product.title || product.name }}</div>
            <div class="product-price">
              <div class="flex-center-start">
                <span>{{ productInfo.unique ? $t('common.price') : $t('common.from') }}</span>
                <span class="total">{{ currency }} {{ total }}</span>
                <span class="original-price"
                      v-if="productInfo.ot_price > 0 && productInfo.price !== productInfo.ot_price">
                  {{ currency }} {{ productInfo.ot_price }}
                </span>
              </div>
            </div>
            <div v-for="(group, index) in productAttr" :key="index">
              <div class="product-title">{{ group.attr_name }}</div>
              <a-space :size="10" class="sepc-wrappr">
                <div class="space-item-inner" @click="onChangeSku(group.attrValue, item)" v-for="item in group.attrValue"
                     :key="item.attr">
                  <button class="default-item" :class="{ 'active-item': item.check }" :disabled="item.disabled">
                    {{ item.attr }}
                  </button>
                </div>
              </a-space>
            </div>
            <div class="product-number" v-if="!iccid">
              <div class="product-title">{{ $t('common.title_4') }}</div>
              <a-space>
                <number-box :value="quantity" :max="maxQuantity" @change="(value) => { quantity = value }" />
                <div class="product-unit-price" v-show="productInfo.unique">
                  {{ currency }}{{ productInfo.price }}/{{ $t('common.each') }}
                </div>
              </a-space>
            </div>
            <a-button type="buynow" :disabled="!productInfo.unique" @click="onBuyNowHandler">
              {{ iccid ? $t('common.btn_2') : $t('common.btn_1') }}
            </a-button>
          </a-col>
        </a-row>
        <div class="explain">
          <!-- <div class="explain-subtitle color-subtitle">{{$t('common.title_3')}}</div> -->
          <div class="html-text" v-html="product.detail"></div>
        </div>
        <div class="section-products" v-if="others.length">
          <div class="section-title">{{ $t('common.other') }}</div>
          <a-row :gutter="[{ xs: 14, sm: 20, xxl: 30 }, { xs: 14, sm: 20, xxl: 30 }]" class="other-products">
            <a-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6" v-for="item in others" :key="item.district_id">
              <div class="product-item" @click="onReplaceHandler(item)" :title="item.district_name">
                <div class="product-cover">
                  <img class="product-img" :src="item.cover" :alt="item.district_name" :title="item.district_name" />
                </div>
                <div class="product-info">
                  <div class="product-name text-overflow-example">{{ item.district_name }}</div>
                  <div class="product-lowest-price">
                    <span class="product-price-from">{{ $t("common.from") }}</span>
                    <span>{{ item.lowest_price }}</span>
                  </div>
                  <div class="product-icon flex">
                    <a-icon type="right" />
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-spin>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import BigNumber from 'bignumber.js';
import powerSet from '../utils/power-set.js'
import TopupSearch from '../components/TopupSearch'
import NumberBox from '../components/NumberBox'
const INITIAL_PRODUCTINFO = {
  unique: '',
  price: 0,
  ot_price: 0,
  spec: ''
}
export default {
  name: 'Details',
  components: {
    TopupSearch,
    NumberBox
  },
  data() {
    return {
      iccid: this.$route.query.iccid,
      maxQuantity:100,
      quantity: 1,
      currency: '',
      others: [],
      product: {},//商品信息
      productDetail: {},//商品信息
      productAttr: [],//套餐数据
      productValue: [],// 套餐组合数据
      productInfo: INITIAL_PRODUCTINFO,
      isLoading: false,
      orders: {
        type: Number(this.$route.query.type),
        specs: '',
        unique: '',
        ot_price: 0,
        price: 0,
        number: 1,
        sim_number: ''
      },
      specs: [],
      products: {
        product: {
          detail: ''
        }
      },
    }
  },
  computed: {
    ...mapGetters("user", ["isLogin"]),
    total() {
      const { unique, price } = this.productInfo
      const { lowest_price } = this.product
      const total = BigNumber(price).times(this.quantity).toFixed(2)
      return unique ? total : lowest_price;
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapMutations(['LOGIN_MODAL_VISIBLE','SET_REDIRECT']),
    async init() {
      try {
        this.isLoading = true
        const { id, iccid } = this.$route.query
        const {
          data: {
            currency,
            product,
            attr,
            value
          }
        } = await this.$http.get(this.APIURL.HOME.DETAILS, { id,...(iccid&&{iccid}) }, false)
        this.currency = currency
        this.product = product
        this.productAttr = attr
        this.productValue = value
        this.pathMap = this.initPathMap(this.productValue)// 初始化路径字典
        this.initDisabledState(this.productAttr)// 初始化禁选状态
        const { data } = await this.$http.get(this.APIURL.HOME.OTHER, { id, ...(iccid && { iccid }) }, false)
        this.others = data
        this.isLoading = false
      } catch (error) {

      }
    },
    // 创建生成路径字典对象函数
    initPathMap(goods) {
      // 重置下单信息
      // resetProductInfo()
      const pathMap = {}
      // 1. 得到所有有效的Sku集合 
      const effectiveSkus = Object.keys(goods).map((key) => {
        return { id: goods[key]['id'], specs: key.split("|") }
      })
      // console.log('effectiveSkus',effectiveSkus)
      // 2. 根据有效的Sku集合使用powerSet算法得到所有子集 [1,2] => [[1], [2], [1,2]]
      effectiveSkus.forEach((sku) => {
        // 2.1 获取可选规格值数组
        const selectedValArr = sku.specs
        // 2.2 获取可选值数组的子集
        // console.log('selectedValArr',selectedValArr)
        const valueArrPowerSet = powerSet(selectedValArr)
        // 3. 根据子集生成路径字典对象
        // 3.1 遍历子集 往pathMap中插入数据
        // console.log('valueArrPowerSet',valueArrPowerSet)
        valueArrPowerSet.forEach((arr) => {
          // 根据Arr得到字符串的key，约定使用-分割 ['1GB'，'1Day'] => '1GB-1Day'
          const key = arr.join('|')
          // 给pathMap设置数据
          if (pathMap[key]) {
            pathMap[key].push(sku.id)
          } else {
            pathMap[key] = [sku.id]
          }
        })
      })
      // console.log(pathMap)
      return pathMap
    },
    // 初始化禁选状态 specs：商品源数据 pathMap：路径字典
    initDisabledState(specs) {
      // 约定：每一个按钮的状态由自身的disabled进行控制
      specs.forEach((item) => {
        item.attrValue = item.attr_values.map((value) => {
          // 路径字典中查找是否有数据 有-可以点击 没有-禁用
          return {
            attr: value,
            check: false,
            disabled: !this.pathMap[value],
          }
        })
      })
    },
    // 选中和取消选中实现
    onChangeSku(group, item) {
      // 点击的是未选中，把同一个规格的其他取消选中，当前点击项选中，点击的是已选中，直接取消
      if (item.check) {
        item.check = false
      } else {
        group.forEach((valItem) => valItem.check = false)
        item.check = true
      }
      // 点击按钮时更新禁选状态
      this.updateDisabledState(this.productAttr)
      this.$forceUpdate();
      // console.log(this.getSelectedValues(this.productAttr))
      // 产出SKU对象数据
      const index = this.getSelectedValues(this.productAttr).findIndex(item => item === undefined)
      if (index > -1) {
        // 信息不完整，初始化当前数量、库存、价格
        this.resetProductInfo()
      } else {
        // 信息完整，获取sku对象
        const key = this.getSelectedValues(this.productAttr).join('|')
        const { unique, price, ot_price } = this.productValue[key]
        this.productInfo = {
          ...this.productInfo,
          unique,
          price,
          ot_price,
          spec: key,
        }
      }
    },
    // 获取选中匹配数组 ['1GB',undefined,undefined]
    getSelectedValues(specs) {
      const arr = []
      specs.forEach((spec) => {
        const selectedVal = spec.attrValue.find((value) => value.check)
        arr.push(selectedVal ? selectedVal.attr : undefined)
      })
      return arr
    },
    // 更新禁选状态
    updateDisabledState(specs) {
      // 约定：每一个按钮的状态由自身的disabled进行控制
      specs.forEach((item, index) => {
        const selectedValues = this.getSelectedValues(specs)
        item.attrValue.forEach((val) => {
          const _seletedValues = [...selectedValues]
          _seletedValues[index] = val.attr
          const key = _seletedValues.filter(value => value).join('|')
          if (val.check) return
          // 路径字典中查找是否有数据 有-可以点击 没有-禁用
          val.disabled = !this.pathMap[key]
        })
      })
    },
    // 重置下单信息
    resetProductInfo() {
      this.productInfo = INITIAL_PRODUCTINFO
    },
    onBuyNowHandler() {
      const { name, cover } = this.product
      const { unique, price, spec } = this.productInfo
      const type = this.iccid ? 1 : 0
      const redirect = {
        name: 'Pay',
        query: {
          price,
          spec,
          name,
          cover,
          type,
          plan_id: unique,
          currency: this.currency,
          ...(this.iccid && { sim_number: this.iccid }),
          ...(!this.iccid && { number: this.quantity }),
        }
      }
      if (!this.isLogin) {
        this.LOGIN_MODAL_VISIBLE(true)
        this.SET_REDIRECT(redirect)
        return
      }
      this.$router.push(redirect);
    },
    onReplaceHandler({ district_id }) {
      const { id, iccid } = this.$route.query
      if (id == district_id) return location.reload();
      const query = {
        id: district_id,
        ...(iccid && { iccid })
      }
      this.$router.replace({ name: 'Details', query });
    },
  }
}
</script>
<style lang="scss" scoped>
.details {
  padding-top: 40px;

  .page-breadcrumb {
    margin-top: 0;
  }

  .product-img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 6px;
  }

  .product-name {
    font-size: 20px;
    color: #000000;
    font-weight: 600;
  }

  .product-price {
    user-select: none;
    height: 75px;
    color: #fff;
    margin: 20px 0;
    padding: 10px 20px;
    background-color: $color;
    transition: all 0.5s ease;

    .total {
      margin-left: 20px;
      font-size: 34px;
    }

    .original-price {
      margin-left: 10px;
      text-decoration: line-through;
    }

    .discount {
      line-height: 2;
      margin-left: 20px;

      .label {
        padding: 1px 6px;
        font-size: 12px;
        border-radius: 4px;
        border: 1px solid #FFFFFF;
      }
    }
  }

  .product-number {
    margin-bottom: 20px;

    .product-title {
      margin-bottom: 20px;
    }

    .product-unit-price {
      user-select: none;
      color: #555555;
      font-size: 18px;

      @include respond-to("phone") {
        font-size: 14px;
      }
    }
  }

  .product-title {
    user-select: none;
  }

  .sepc-wrappr {
    padding: 10px 0 20px 0;
    flex-wrap: wrap;

    .space-item-inner {
      position: relative;
      margin-top: 10px;

      .discount-icon {
        pointer-events: none;
        cursor: pointer;
        position: absolute;
        width: 32px;
        height: auto;
        top: -2px;
        right: -2px;
        transition: all 0.5s ease;
      }
    }

    .default-item {
      user-select: none;
      position: relative;
      box-sizing: border-box;
      cursor: pointer;
      padding: 10px 15px;
      min-width: 98px;
      color: #222222;
      font-size: 16px;
      font-weight: bold;
      border: 1px solid #999999;
      border-radius: 6px;
      background-color: #fff;
      transition: all 0.5s ease;
      box-shadow: inset 0 0 0 0 rgba(37, 100, 246, .1);

      &:disabled {
        cursor: not-allowed;
        border-color: #C5C5C5;
        color: rgba(0, 0, 0, 0.25);
        background-color: rgba(0, 0, 0, 0.02);
      }

    }

    .active-item {
      color: $color;
      border-color: $color;
      box-shadow: inset 0 -100px 0 0 rgba(37, 100, 246, .1);
    }
  }

  .explain {
    padding: 40px 0;
    border-bottom: 1px solid #F2F3F6;

    .explain-subtitle {
      color: #333333;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .color-subtitle {
      color: $color;
    }

    .html-text {
      color: #666666;

      /deep/img {
        width: 100% !important;
        height: auto !important;
      }

      /deep/ p {
        line-height: 2;
        margin-bottom: 16px;

        img {
          width: 100% !important;
          height: auto !important;
        }

        span {
          font-size: 16px !important;
          font-family: "SourceHanSans", sans-serif !important;

          a {
            color: $color;
          }

          img {
            width: 100% !important;
            height: auto !important;
          }
        }
      }
    }
  }
}

@media(max-width:992px) {
  .details {
    padding-top: 20px;

    .product-name {
      font-size: 18px;
    }

    .product-price {
      height: 50px;
      margin: 15px 0;
      padding: 10px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .total {
        margin-left: 10px;
        font-size: 20px;
      }

      .discount {
        line-height: 2;
        margin-left: 10px;

        .label {
          padding: 1px 6px;
          font-size: 12px;
          border-radius: 4px;
          border: 1px solid #FFFFFF;
        }
      }
    }

    .sepc-wrappr {
      padding: 10px 0 15px 0;

      .default-item {
        cursor: pointer;
        padding: 10px;
        min-width: 80px;
        font-size: 14px;
      }
    }

    .explain {
      padding: 20px 0;

      .explain-subtitle {
        font-size: 16px;
      }

      .html-text {
        /deep/ p {
          font-size: 14px;
          line-height: 1.5;
          margin-bottom: 10px;

          span {
            line-height: 1.5;
            font-size: 14px !important;
            font-family: "SourceHanSans", sans-serif !important;
          }
        }
      }
    }
  }
}
</style>